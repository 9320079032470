<template>
  <v-card-text>
    <v-form ref="form">
      <v-row>
        <v-col>
          <!-- <span v-if="!isNew">
            {{ careNoteData.dueDateTimeStartDp }} ~ {{ careNoteData.dueDateTimeEndDp }}
            <b v-if="careNoteData.diffSec > 0">(Time : {{ $helper.secondsToTime(careNoteData.diffSec) }})</b>
          </span>
          <span v-else> -->
          <span>
            <div class="newDueBox">
              <div>
                <DateCompWithPeriodTimes 
                  propsPlaceholder="Date" 
                  :propsData="careNoteData.careTimeSet"
                  @complete="dateTimeComplete"/>
              </div>
              <span class="spanGap">
                <b v-if="careNoteData.diffSec > 0"> (Time : {{ $helper.secondsToTime(careNoteData.diffSec) }})</b>
              </span>
            </div>
          </span>
        </v-col>
      </v-row>
      <!-- <v-row v-if="careNoteData.cn_id > 0">
        <v-col class="py-1">
          <v-divider/>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col>
          <CheckComps
            ref="refServiceType"
            :isHorizontal="true"
            :items="serviceTypeItems"
            :value="serviceTypeValue"
            @changeType="changeServiceType"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-divider/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CheckComps
            ref="refActiveLog"
            :isHorizontal="true"
            :items="activeLogItems"
            :value="activeLogValue"
            @changeType="changeActiveLog"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-1">
          <v-divider/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CheckComps
            ref="refActiveType"
            :isHorizontal="false"
            :items="activeLogTypeItems"
            :value="activeLogTypeValue"
            @changeType="changeActiveLogType"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            label="Care note"
            v-model="careNoteData.careNote"
            autofocus
            outlined
            class="mt-6"
            maxlength="400"
            counter
          />
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import DateCompWithPeriodTimes from "@/components/commonV2/ui/DateCompWithPeriodTimes.vue";
import CheckComps from "@/components/commonV2/ui/CheckComps.vue";
const requireService = require('@/utils/requiredValue.js');
const formatService = require('@/utils/format.js');
export default {
  components: {
    DateCompWithPeriodTimes,
    CheckComps,
  },
  props: {
    careNoteData: {
      type: [Object, Array],
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    careNoteData(val) {
      if (val === null) return;
      this.serviceTypeValue = this.$helper.changeStringToArr(val.program);
      this.activeLogValue = this.$helper.changeStringToArr(val.activeLog);
      this.activeLogTypeValue = this.$helper.changeStringToArr(val.activeLogType);
    },
  },
  data() {
    return {
      requiredMsg: requireService.requiredFieldAlertMessage(),
      propsCalendarStart: this.$moment(),
      propsCalendarEnd: this.$moment(),
      serviceType: this.careNoteData === null ? [] : this.careNoteData.careType,
      activeLog: this.careNoteData === null ? [] : this.careNoteData.activeLogArr,
      activeType: this.careNoteData === null ? [] : this.careNoteData.activeTypeArr,
      serviceTypeItems: formatService.serviceTypeItems(),
      serviceTypeValue:
        this.careNoteData.program === "" ? [] : this.$helper.changeStringToArr(this.careNoteData.program),
      activeLogItems: formatService.activeLogItems(),
      activeLogValue: 
        this.careNoteData.activeLog === "" ? [] : this.$helper.changeStringToArr(this.careNoteData.activeLog),
      activeLogTypeItems: formatService.activeLogTypeItems(),
      activeLogTypeValue: 
        this.careNoteData.activeLogType === "" ? [] : this.$helper.changeStringToArr(this.careNoteData.activeLogType),
      
    };
  },
  methods: {
    _getTimeSet() {
      console.log('_getTimeSet');
      this.careNoteData.careTimeSet = {
        date: this.$helper.getToDateStringType(),
        startTime: this.mPatient.careTime.startTime,
        endTime: this.mPatient.careTime.endTime
      }
      this.careNoteData.diffSec = this.mPatient.careTime.tik;
      // this.careNoteData.dueDateTimeStartDp = this.$helper.changeDateToStringForDp(this.mPatient.careTime.sDate, true);
      // this.careNoteData.dueDateTimeEndDp = "";  
      // this.careNoteData.diffSec = this.mPatient.careTime.tik;
    },
    changeServiceType(e) {
      this.careNoteData.program = e.toString();
    },
    changeActiveLog(e) {
      this.careNoteData.activeLog = e.toString();
    },
    changeActiveLogType(e) {
      this.careNoteData.activeLogType = e.toString();
    },
    dateTimeComplete(e) {
      console.log('dateTimeComplete', e);
      this.careNoteData.careTimeSet.date = e.date;
      this.careNoteData.careTimeSet.startTime = e.startTime;
      this.careNoteData.careTimeSet.endTime = e.endTime;
      this.careNoteData.diffSec = e.dueTime;
    },
  },
  computed: {
    ...mapState(['mPatient']),
  },
};
</script>
<style lang="scss">
.spanGap {
  margin: 5px;
}
.newDueBox {
  display: flex;
  align-items: center;
  vertical-align: middle;
}
._checkBox {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}
</style>